<template>
<div class="editnotification-container">
    <div class="top">

        <div class="fields">
            <div class="field" >
                <p>Tipo CTA</p>
                <vs-select v-model="notification.call_to_action_type">
                    <vs-option label="Nessuna" value="None">
                        Nessuna
                    </vs-option>
                    <vs-option label="URL" value="URL">
                        URL
                    </vs-option>
                </vs-select>
            </div>
            <div class="field" >
                <p>CTA</p>
                <vs-input type="text" v-model="notification.call_to_action_value" />
            </div>
            <template v-if="notification.localized != null">
                <template v-for="l in notification.localized">
                    <div class="field" :key="'langtitle-' + l.language_id">
                        <p>Titolo [{{ languages.find(e => e.id == l.language_id).name }}]</p>
                        <vs-input type="text" v-model="l.title" />
                    </div>
                    <div class="field" :key="'langtitle-' + l.language_id">
                        <p>Contenuto [{{ languages.find(e => e.id == l.language_id).name }}]</p>
                        <vs-input type="text" v-model="l.text" />
                    </div>
                </template>
            </template>

            <vs-button v-if="notification.id == null" size="large" :loading="loading" @click="editNotificationEvent">{{ $t('common.saveChanges').toUpperCase() }}</vs-button>
            <vs-button v-if="notification.id != null" danger border size="large" :loading="deleting" @click="deleteNotificationEvent">{{ $t('common.delete').toUpperCase() }}</vs-button>

        </div>
    </div>

</div>
</template>

<script>
import {
    apiCall,
    baseUri
} from '../../utils/ApiMiddleware';
export default {
    name: "editnotification",
    props: {
        notification: {
            type: Object,
            required: true
        },
        editNotification: {
            type: Function,
            required: true
        },

        user: {
            type: Object,
            required: false,
            default: null
        },

        deleted: {
            type: Function,
            required: true
        }
    },
    components: {},
    data() {
        return {
            loading: false,
            languages: [],
            lang: null,
            baseUri,
            deleting: false,
        }
    },

    async mounted() {
        const response = await apiCall('GET', '/users/languages');
        if (response.status != 200) {
            this.$vs.notification({
                color: 'danger',
                position: 'top-right',
                title: this.$t('common.messages.somethingWentWrong'),
                text: 'Impossibile ottenere le lingue'
            })
            return;
        }
        this.languages = response.data.language;
        this.lang = this.languages.find(e => e.id == this.user.language.id).id

        if (this.notification.id == null) {
            this.notification.localized = this.languages.map(e => ({
                language_id: e.id,
                text: '',
                title: ''
            }))
        }
        if(this.notification.call_to_action_type == null || this.notification.call_to_action_type == ''){
            this.notification.call_to_action_type = 'None';
        }
    },

    methods: {

        async deleteNotificationEvent() {
            this.deleting = true;
            const response = await apiCall('DELETE', '/backoffice/notifications/' + this.notification.id);
            this.deleting = false;
            // if status code is 200, show a success message 
            if (response.status == 200) {
                this.$vs.notification({
                    title: this.$t('common.messages.success'),
                    text: 'Notifica eliminata con successo',
                    color: 'success',
                    position: 'top-right'
                });
                this.deleted();
            } else if (response.status != 0) {
                this.$vs.notification({
                    title: this.$t('common.messages.somethingWentWrong'),
                    text: 'Impossibile eliminare la notifica',
                    color: 'danger',
                    position: 'top-right'
                });
            }

        },

        async editNotificationEvent() {

            this.loading = true;
            var temp = JSON.parse(JSON.stringify(this.notification));
            if(temp.call_to_action_type == 'None'){
                temp.call_to_action_value = '';
            }
            await this.editNotification(temp);
            this.loading = false;

        },

    }
}
</script>

<style scoped>
.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;

}

.top {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.fields {
    display: flex;
    flex-direction: column;
}

.field {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    border-bottom: 1px solid var(--grey-75);
}

.field p {
    width: 35%;
    max-width: 35%;
}

.editnotification-container {

    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    height: calc(100% - 75px);

    justify-content: space-between;
}

/* MOBILE */
@media (max-width: 600px) {
    .editnotification-container {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        box-sizing: border-box;

    }

}

textarea {
    border-radius: 5px;
    border: 1px solid var(--grey-75);
    height: 150px;
    max-height: 150px;
    padding: 10px;
    font-family: Poppins;
}
</style><style>
.editnotification-container .field .vs-input {
    background: transparent !important;
    font-size: 1rem;
}

.editnotification-container .field .vs-select__input {
    background: transparent !important;
    font-size: 1rem;
}

.editnotification-container .field input::-webkit-date-and-time-value {
    text-align: left !important;
    font-size: 1rem;
}
</style>
